"use client";

import { BookOpenIcon, HeartIcon } from "@heroicons/react/24/outline";
import { useSession } from "next-auth/react";
import { useState } from "react";
import { addEditCollection, addEditWishlist } from "../../../utils/clientFetch";

const ItemActionsMini = (props: {
  productSlug: any;
  isInCatalogList: boolean | undefined;
  isInWishList: boolean | undefined;
}) => {
  const { data: session, status } = useSession();
  const [isInWishlist, setIsInWishlist] = useState(props.isInWishList);
  const [isInCatalog, setIsInCatalog] = useState(props.isInCatalogList);

  if (props.isInCatalogList === undefined) return null;
  if (props.isInWishList === undefined) return null;
  if (status === "unauthenticated") return null;

  const wishListHandler = () => {
    if (status !== "loading") {
      if (session) {
        if (isInWishlist) {
          return async () => {
            await addEditWishlist(props.productSlug, "remove");
            setIsInWishlist(false);
          };
        } else {
          return async () => {
            await addEditWishlist(props.productSlug, "add");
            setIsInWishlist(true);
          };
        }
      } else {
        return () => {
          window.location.href = "/login";
        };
      }
    }
  };

  const collectionHandler = () => {
    if (status !== "loading") {
      if (session) {
        if (isInCatalog) {
          return async () => {
            await addEditCollection(props.productSlug, "remove");
            setIsInCatalog(false);
          };
        } else {
          return async () => {
            await addEditCollection(props.productSlug, "add");
            setIsInCatalog(true);
          };
        }
      } else {
        return () => {
          window.location.href = "/login";
        };
      }
    }
  };

  return (
    <div className="my-1 flex grow flex-col mb-2 inline-flex">
      <div className="flex self-center grow mt-2">
        <div className="flex">
          {isInWishlist ? (
            <>
              <button
                type="button"
                onClick={wishListHandler()}
                className="flex inline-flex items-center rounded-md border border-gray-300 bg-[#dc2626]/5 p-2 sm:p-1 mx-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 flex-col"
              >
                <div className="flex min-w-full justify-center">
                  <HeartIcon
                    className="text-[#dc2626] h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={wishListHandler()}
                className="flex inline-flex items-center rounded-md border border-gray-300 bg-white p-2 sm:p-1 mx-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 flex-col"
              >
                <div className="flex min-w-full justify-center">
                  <HeartIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </button>
            </>
          )}
          {isInCatalog ? (
            <>
              <button
                type="button"
                onClick={collectionHandler()}
                className="flex inline-flex items-center rounded-md border border-gray-300 bg-[#f97316]/5 p-2 sm:p-1 mx-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 flex-col"
              >
                <div className="flex min-w-full justify-center">
                  <BookOpenIcon
                    className="text-[#f97316] h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={collectionHandler()}
                className="flex inline-flex items-center rounded-md border border-gray-300 bg-white p-2 sm:p-1 mx-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 flex-col"
              >
                <div className="flex min-w-full justify-center">
                  <BookOpenIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemActionsMini;
