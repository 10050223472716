import Image from "next/image";

export const CoinSVG = () => {
  return (
    <svg
      className="lg:h-20 lg:w-20 xl:h-24 xl:w-24 2xl:w-28 2xl:h-28 h-20 w-20 flex-shrink-0 p-1 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1280 1280"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M6140 12794 c-1043 -56 -1966 -314 -2824 -788 -538 -298 -963 -616 -1404 -1051 -924 -909 -1533 -2037 -1787 -3307 -87 -438 -119 -773 -119 -1248 0 -318 7 -466 35 -705 118 -1032 470 -1997 1039 -2850 395 -592 922 -1147 1492 -1573 856 -639 1868 -1056 2928 -1206 336 -48 509 -59 900 -59 391 0 564 11 900 59 1344 190 2593 803 3554 1744 359 352 639 689 891 1074 523 798 856 1680 990 2620 46 326 59 518 59 896 0 390 -13 570 -65 935 -151 1045 -576 2061 -1218 2913 -212 280 -369 458 -633 717 -307 302 -583 528 -923 755 -904 604 -1913 956 -3018 1056 -156 14 -663 25 -797 18z" />
      </g>
    </svg>
  );
};

export const BankNoteSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      className="flex justify-center items-center lg:h-20 lg:w-20 xl:h-24 xl:w-24 2xl:w-28 2xl:h-28 h-20 w-20 flex-shrink-0 p-1"
      viewBox="0 0 320 300"
    >
      <g>
        <path
          className="opacity-100"
          fill="#9ca3af"
          d="M 7.5,80.5 C 108.833,80.5 210.167,80.5 311.5,80.5C 311.5,134.167 311.5,187.833 311.5,241.5C 210.167,241.5 108.833,241.5 7.5,241.5C 7.5,187.833 7.5,134.167 7.5,80.5 Z"
        />
      </g>
    </svg>
  );
};

export const CoinNoImageImg250 = () => {
  return (
    <div className="flex justify-center items-center lg:h-20 lg:w-20 xl:h-24 xl:w-24 2xl:w-28 2xl:h-28 h-20 w-20 flex-shrink-0 p-1 max-w-full">
      <Image src="/coin.png" width={250} height={250} alt="No image" />
    </div>
  );
};

export const CoinNoImageBig = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="320px"
      height="320px"
    >
      <g>
        <path
          className="opacity-100"
          fill="#9ca3af"
          d="M 149.5,3.5 C 218.638,2.63457 269.138,32.6346 301,93.5C 326.289,154.691 318.289,211.024 277,262.5C 227.09,313.62 168.257,327.453 100.5,304C 43.3373,278.222 11.1706,234.055 4,171.5C 2.0542,101.53 31.8875,50.3629 93.5,18C 111.526,10.1689 130.192,5.33554 149.5,3.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dce2eb"
          d="M 160.5,95.5 C 164.527,95.2596 165.861,96.9263 164.5,100.5C 162.356,101.953 160.523,101.62 159,99.5C 158.7,97.9343 159.2,96.601 160.5,95.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dce2eb"
          d="M 131.5,96.5 C 136.599,96.1746 141.599,96.6746 146.5,98C 150.073,99.7343 151.74,102.568 151.5,106.5C 149.833,106.5 148.167,106.5 146.5,106.5C 145.646,101.97 142.812,99.9701 138,100.5C 133.372,100.391 130.539,102.557 129.5,107C 129.643,109.151 130.643,110.818 132.5,112C 135.648,113.605 138.981,114.605 142.5,115C 153.286,119.058 155.286,125.725 148.5,135C 140.565,139.957 133.065,139.457 126,133.5C 124.589,131.35 123.755,129.016 123.5,126.5C 127.162,125.637 129.328,126.97 130,130.5C 135.902,134.639 141.569,134.305 147,129.5C 147.944,126.653 147.444,124.153 145.5,122C 140.12,119.994 134.787,117.994 129.5,116C 122.561,108.787 123.227,102.287 131.5,96.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dde3eb"
          d="M 159.5,106.5 C 161.167,106.5 162.833,106.5 164.5,106.5C 164.5,116.833 164.5,127.167 164.5,137.5C 162.833,137.5 161.167,137.5 159.5,137.5C 159.5,127.167 159.5,116.833 159.5,106.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dce2eb"
          d="M 172.5,106.5 C 174.167,106.5 175.833,106.5 177.5,106.5C 177.03,110.97 178.03,111.47 180.5,108C 187.116,104.933 192.283,106.433 196,112.5C 196.5,120.827 196.666,129.16 196.5,137.5C 194.833,137.5 193.167,137.5 191.5,137.5C 191.666,129.826 191.5,122.159 191,114.5C 188.369,110.371 184.869,109.538 180.5,112C 179.299,112.903 178.465,114.069 178,115.5C 177.5,122.826 177.334,130.159 177.5,137.5C 175.833,137.5 174.167,137.5 172.5,137.5C 172.5,127.167 172.5,116.833 172.5,106.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dce2eb"
          d="M 70.5,161.5 C 74.5273,161.26 75.8607,162.926 74.5,166.5C 72.3561,167.953 70.5227,167.62 69,165.5C 68.7003,163.934 69.2003,162.601 70.5,161.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dde3eb"
          d="M 69.5,172.5 C 71.1667,172.5 72.8333,172.5 74.5,172.5C 74.5,182.833 74.5,193.167 74.5,203.5C 72.8333,203.5 71.1667,203.5 69.5,203.5C 69.5,193.167 69.5,182.833 69.5,172.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dde3ec"
          d="M 82.5,172.5 C 84.1667,172.5 85.8333,172.5 87.5,172.5C 87.3671,177.272 88.7004,177.438 91.5,173C 96.7348,171.534 100.901,173.034 104,177.5C 107.776,172.288 112.61,171.121 118.5,174C 120.049,175.214 121.215,176.714 122,178.5C 122.5,186.827 122.666,195.16 122.5,203.5C 120.833,203.5 119.167,203.5 117.5,203.5C 117.666,195.493 117.5,187.493 117,179.5C 112.574,175.219 108.574,175.552 105,180.5C 104.5,188.159 104.334,195.826 104.5,203.5C 103.167,203.5 101.833,203.5 100.5,203.5C 100.666,195.826 100.5,188.159 100,180.5C 96.1923,175.51 92.1923,175.51 88,180.5C 87.5003,188.159 87.3336,195.826 87.5,203.5C 85.8333,203.5 84.1667,203.5 82.5,203.5C 82.5,193.167 82.5,182.833 82.5,172.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dde3eb"
          d="M 137.5,172.5 C 142.364,172.001 146.864,173.001 151,175.5C 151.667,176.833 152.333,178.167 153,179.5C 153.5,187.493 153.666,195.493 153.5,203.5C 151.833,203.5 150.167,203.5 148.5,203.5C 148.649,202.448 148.483,201.448 148,200.5C 147.097,201.701 145.931,202.535 144.5,203C 133.347,205.837 128.514,201.671 130,190.5C 130.833,189.667 131.667,188.833 132.5,188C 137.306,186.131 142.306,185.131 147.5,185C 149.52,180.621 148.187,177.788 143.5,176.5C 139.114,177.941 134.78,179.274 130.5,180.5C 131.4,176.599 133.733,173.932 137.5,172.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#e0e6ee"
          d="M 181.5,207.5 C 182.638,204.696 182.805,201.696 182,198.5C 181.283,199.044 180.783,199.711 180.5,200.5C 174.074,205.034 168.24,204.368 163,198.5C 159.156,190.113 159.989,182.28 165.5,175C 171.745,171.184 177.245,172.017 182,177.5C 182.494,175.866 182.66,174.199 182.5,172.5C 183.833,172.5 185.167,172.5 186.5,172.5C 186.667,184.505 186.5,196.505 186,208.5C 185.027,212.406 182.527,214.573 178.5,215C 171.678,216.882 166.012,215.216 161.5,210C 164.316,207.016 166.982,207.349 169.5,211C 174.344,212.253 178.344,211.086 181.5,207.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dfe5ed"
          d="M 202.5,172.5 C 214.48,171.813 220.146,177.48 219.5,189.5C 212.5,189.5 205.5,189.5 198.5,189.5C 198.481,197.642 202.481,200.808 210.5,199C 212.344,197.818 214.177,196.651 216,195.5C 218.984,196.833 219.151,198.667 216.5,201C 199.513,206.693 192.013,200.526 194,182.5C 195.261,177.745 198.095,174.412 202.5,172.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#dce2eb"
          d="M 226.5,172.5 C 228.167,172.5 229.833,172.5 231.5,172.5C 231.03,176.97 232.03,177.47 234.5,174C 241.116,170.933 246.283,172.433 250,178.5C 250.5,186.827 250.666,195.16 250.5,203.5C 248.833,203.5 247.167,203.5 245.5,203.5C 245.666,195.826 245.5,188.159 245,180.5C 242.369,176.371 238.869,175.538 234.5,178C 233.299,178.903 232.465,180.069 232,181.5C 231.5,188.826 231.334,196.159 231.5,203.5C 229.833,203.5 228.167,203.5 226.5,203.5C 226.5,193.167 226.5,182.833 226.5,172.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#9fa6b1"
          d="M 170.5,176.5 C 176.308,175.652 180.142,177.985 182,183.5C 183.879,190.101 182.046,195.268 176.5,199C 170.137,200.401 166.303,197.901 165,191.5C 163.789,185.293 165.622,180.293 170.5,176.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#9ea5b1"
          d="M 203.5,176.5 C 210.571,175.583 214.237,178.583 214.5,185.5C 209.167,185.5 203.833,185.5 198.5,185.5C 198.372,181.415 200.039,178.415 203.5,176.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#9ea5b1"
          d="M 145.5,188.5 C 146.5,188.5 147.5,188.5 148.5,188.5C 149.013,197.153 145.013,200.653 136.5,199C 132.345,195.105 133.011,192.105 138.5,190C 141.036,189.814 143.369,189.314 145.5,188.5 Z"
        />
      </g>
      <g>
        <path
          className="opacity-100"
          fill="#b8bfc9"
          d="M 181.5,207.5 C 181.802,204.941 181.468,202.607 180.5,200.5C 180.783,199.711 181.283,199.044 182,198.5C 182.805,201.696 182.638,204.696 181.5,207.5 Z"
        />
      </g>
    </svg>
  );
};

export const BanknoteNoImage = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="320px"
        height="184px"
        className="rounded-lg max-w-[320px] max-h-[320px]"
      >
        <g>
          <path
            className="opacity-100"
            fill="#9ca3af"
            d="M 7.5,10.5 C 108.833,10.5 210.167,10.5 311.5,10.5C 311.5,64.1667 311.5,117.833 311.5,171.5C 210.167,171.5 108.833,171.5 7.5,171.5C 7.5,117.833 7.5,64.1667 7.5,10.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dee4ec"
            d="M 160.5,44.5 C 164.161,44.3501 165.161,45.8501 163.5,49C 159.707,49.4866 158.707,47.9866 160.5,44.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dce2ea"
            d="M 137.5,45.5 C 142.08,45.1134 146.414,45.9468 150.5,48C 152.451,49.7342 153.451,51.9009 153.5,54.5C 152.022,54.762 150.689,54.4287 149.5,53.5C 148.051,50.2216 145.551,48.8883 142,49.5C 138.014,48.992 135.514,50.6587 134.5,54.5C 134.529,56.0293 135.196,57.196 136.5,58C 140.392,59.6401 144.392,60.9735 148.5,62C 154.84,66.4307 155.174,71.4307 149.5,77C 143.333,79.3892 137.5,78.8892 132,75.5C 130.991,73.9751 130.491,72.3084 130.5,70.5C 131.978,70.238 133.311,70.5713 134.5,71.5C 135.791,74.2971 137.958,75.6304 141,75.5C 148.648,75.5697 150.815,72.403 147.5,66C 143.5,64.6667 139.5,63.3333 135.5,62C 128.615,55.6813 129.282,50.1813 137.5,45.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#e1e8ef"
            d="M 160.5,53.5 C 161.5,53.5 162.5,53.5 163.5,53.5C 163.5,61.8333 163.5,70.1667 163.5,78.5C 162.5,78.5 161.5,78.5 160.5,78.5C 160.5,70.1667 160.5,61.8333 160.5,53.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dee4ec"
            d="M 170.5,53.5 C 171.5,53.5 172.5,53.5 173.5,53.5C 173.367,58.2717 174.7,58.4384 177.5,54C 182.504,52.7746 186.337,54.2746 189,58.5C 189.5,65.1583 189.666,71.825 189.5,78.5C 188.167,78.5 186.833,78.5 185.5,78.5C 185.666,72.1579 185.5,65.8246 185,59.5C 181.057,56.6342 177.39,56.9676 174,60.5C 173.501,66.4908 173.334,72.4908 173.5,78.5C 172.5,78.5 171.5,78.5 170.5,78.5C 170.5,70.1667 170.5,61.8333 170.5,53.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dee4ec"
            d="M 86.5,98.5 C 90.1612,98.3501 91.1612,99.8501 89.5,103C 85.7071,103.487 84.7071,101.987 86.5,98.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#e1e8ef"
            d="M 86.5,107.5 C 87.5,107.5 88.5,107.5 89.5,107.5C 89.5,115.833 89.5,124.167 89.5,132.5C 88.5,132.5 87.5,132.5 86.5,132.5C 86.5,124.167 86.5,115.833 86.5,107.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dce2ea"
            d="M 97.5,107.5 C 98.5,107.5 99.5,107.5 100.5,107.5C 100.21,111.554 100.877,112.054 102.5,109C 107.125,106.657 110.958,107.49 114,111.5C 119.602,105.926 124.602,106.259 129,112.5C 129.5,119.158 129.666,125.825 129.5,132.5C 128.167,132.5 126.833,132.5 125.5,132.5C 125.666,126.158 125.5,119.825 125,113.5C 122.24,111.297 119.24,110.964 116,112.5C 115.5,119.158 115.334,125.825 115.5,132.5C 114.167,132.5 112.833,132.5 111.5,132.5C 111.666,126.158 111.5,119.825 111,113.5C 107.381,110.657 104.048,110.99 101,114.5C 100.501,120.491 100.334,126.491 100.5,132.5C 99.5,132.5 98.5,132.5 97.5,132.5C 97.5,124.167 97.5,115.833 97.5,107.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dde3eb"
            d="M 142.5,107.5 C 147.635,106.426 151.468,108.093 154,112.5C 154.5,119.158 154.666,125.825 154.5,132.5C 153.167,132.5 151.833,132.5 150.5,132.5C 150.557,128.867 149.557,128.7 147.5,132C 140.67,134.312 136.337,132.145 134.5,125.5C 134.406,123.088 135.406,121.255 137.5,120C 141.663,118.334 145.997,117.501 150.5,117.5C 150.838,112.335 148.505,110.169 143.5,111C 141.656,112.182 139.823,113.349 138,114.5C 135.127,113.586 134.96,112.086 137.5,110C 139.315,109.243 140.981,108.41 142.5,107.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dce2ea"
            d="M 166.5,107.5 C 170.459,107.071 173.959,108.071 177,110.5C 177.483,109.552 177.649,108.552 177.5,107.5C 178.833,107.5 180.167,107.5 181.5,107.5C 181.666,117.506 181.5,127.506 181,137.5C 176.433,142.681 170.933,143.847 164.5,141C 161.078,139.594 160.745,137.761 163.5,135.5C 167.778,139.031 172.278,139.365 177,136.5C 177.667,134.167 177.667,131.833 177,129.5C 169.382,134.616 163.715,132.95 160,124.5C 158.556,117.317 160.723,111.65 166.5,107.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dde3eb"
            d="M 194.5,107.5 C 204.698,106.7 209.698,111.367 209.5,121.5C 203.5,121.5 197.5,121.5 191.5,121.5C 192.162,127.67 195.495,130.17 201.5,129C 203.067,127.924 204.734,127.091 206.5,126.5C 207.167,126.833 207.833,127.167 208.5,127.5C 206.62,131.535 203.453,133.202 199,132.5C 193.432,132.616 189.765,129.95 188,124.5C 186.556,117.317 188.723,111.65 194.5,107.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#dee4ec"
            d="M 215.5,107.5 C 216.5,107.5 217.5,107.5 218.5,107.5C 218.367,112.272 219.7,112.438 222.5,108C 227.504,106.775 231.337,108.275 234,112.5C 234.5,119.158 234.666,125.825 234.5,132.5C 233.167,132.5 231.833,132.5 230.5,132.5C 230.666,126.158 230.5,119.825 230,113.5C 226.057,110.634 222.39,110.968 219,114.5C 218.501,120.491 218.334,126.491 218.5,132.5C 217.5,132.5 216.5,132.5 215.5,132.5C 215.5,124.167 215.5,115.833 215.5,107.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#9ea5b1"
            d="M 168.5,110.5 C 172.493,110.245 175.326,111.911 177,115.5C 179.598,125.222 176.098,129.389 166.5,128C 163.64,124.319 162.807,120.152 164,115.5C 165.059,113.341 166.559,111.674 168.5,110.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#9da4b0"
            d="M 205.5,117.5 C 200.706,118.097 196.373,117.43 192.5,115.5C 192.511,113.983 193.177,112.816 194.5,112C 197.167,111.333 199.833,111.333 202.5,112C 204.607,113.264 205.607,115.097 205.5,117.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#bcc2cc"
            d="M 192.5,115.5 C 196.373,117.43 200.706,118.097 205.5,117.5C 201.03,118.486 196.363,118.819 191.5,118.5C 191.263,117.209 191.596,116.209 192.5,115.5 Z"
          />
        </g>
        <g>
          <path
            className="opacity-100"
            fill="#a0a6b2"
            d="M 146.5,120.5 C 147.833,120.5 149.167,120.5 150.5,120.5C 150.536,129.306 146.703,131.639 139,127.5C 138.333,126.167 138.333,124.833 139,123.5C 141.48,122.188 143.98,121.188 146.5,120.5 Z"
          />
        </g>
      </svg>
    </>
  );
};

export const BanknoteNoImageImg250 = () => {
  return (
    <div className="flex justify-center items-center lg:h-20 lg:w-20 xl:h-24 xl:w-24 2xl:w-28 2xl:h-28 h-20 w-20 flex-shrink-0 p-1 max-w-full">
      <Image src="/banknote.png" width={250} height={250} alt="No image" />
    </div>
  );
};
