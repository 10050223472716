"use client";
import React, { useRef, useEffect, ReactNode } from "react";

interface AutoResizeTextProps {
  children: ReactNode;
}

const AutoResizeText: React.FC<AutoResizeTextProps> = ({ children }) => {
  const paraRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (paraRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(paraRef.current).lineHeight
      );
      const height = paraRef.current.offsetHeight;
      const lines = Math.floor(height / lineHeight);

      if (lines > 3) {
        paraRef.current.classList.remove("text-sm");
        paraRef.current.classList.add("text-xs");
      }
    }
  }, []);

  return (
    <p ref={paraRef} className="text-gray-900 text-sm min-h-[2rem]">
      {children}
    </p>
  );
};

export default AutoResizeText;
