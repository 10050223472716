import { fetchErrorHandler } from "./ErrorHandler";
import { CatalogItemNoImg } from "./../app/types";

const origin =
  typeof window !== "undefined" && window.location.origin
    ? window.location.origin
    : "";
const apiURL = `${origin}/api/`;

export const FetchItems = async (
  path: any,
  page: any,
  sort: string,
  onlyImages: string | null
): Promise<{ itemList: CatalogItemNoImg[]; metadata: any }> => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ page: page, sort: sort, onlyImages: onlyImages }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}getItems${path}`, options);
  return await fetchErrorHandler(res);
};

export const FetchRelatedItems = async (
  currencySlug: string,
  value: string | null
) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ currencySlug: currencySlug, value: value }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}getRelatedItems`, options);
  return await fetchErrorHandler(res);
};

export const FetchRandomCoins = async () => {
  const options: RequestInit = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}getRandomCoins`, options);
  return await fetchErrorHandler(res);
};

export const FetchRandomBanknotes = async () => {
  const options: RequestInit = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}getRandomBanknotes`, options);
  return await fetchErrorHandler(res);
};

export const SearchItems = async (
  searchquery: string,
  page: any,
  sort: string
) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ page: page, sort: sort }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };

  const res = await fetch(`${apiURL}search/${searchquery}`, options);
  return await fetchErrorHandler(res);
};

export const PageView = async () => {
  const options: RequestInit = {
    method: "GET",
  };
  const res = await fetch(`${apiURL}pageview`, options);
  return await fetchErrorHandler(res);
};

export const VoteDescription = async (slug: string, vote: number) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ slug: slug, vote: vote }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}voteProductDescription`, options);
  return await fetchErrorHandler(res);
};

export const subscribeUser = async (
  email: string,
  productSlug: string | null
) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ email: email, productSlug: productSlug }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}subscribeUser`, options);
  return await fetchErrorHandler(res);
};

export const getItemUserStatus = async (productSlug: string | null) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ productSlug: productSlug }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}getItemUserStatus`, options);
  return await fetchErrorHandler(res);
};

export const addEditWishlist = async (
  productSlug: string | null,
  action: string
) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ productSlug: productSlug, action: action }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}addEditWishlist`, options);
  return await fetchErrorHandler(res);
};

export const addEditCollection = async (
  productSlug: string | null,
  action: string
) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ productSlug: productSlug, action: action }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}addEditCollection`, options);
  return await fetchErrorHandler(res);
};

export const signUpBetaSeller = async (phoneNumber: string | null) => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ phoneNumber: phoneNumber }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  const res = await fetch(`${apiURL}signUpBetaSeller`, options);
  return await fetchErrorHandler(res);
};
