export const fetchErrorHandler = async (fetchRes: any) => {
  if (fetchRes.status === 200) return await noError(fetchRes);

  await interceptBadReq(fetchRes);
};

const noError = async (fetchRes: any) => {
  try {
    const res = await fetchRes.json();
    return res;
  } catch (error) {
    console.error(error);
    await interceptBadReq(fetchRes);
  }
};

const interceptBadReq = async (fetchRes: any) => {
  console.log(
    `##### Fetch failed ####\n\n ${fetchRes.url} | error ${fetchRes.status}, ${fetchRes.statusText}`
  );
};

const badResponse = (fetchRes: any) => {
  console.log(`##### Bad response ####\n\n ${fetchRes}`);
};
