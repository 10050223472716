import { CatalogItemNoImg } from "../../types";
import AutoResizeText from "./AutoResizeText";
import { BanknoteNoImageImg250, CoinNoImageImg250 } from "./NoImagePlaceholder";
import FlagIcon from "../FlagIcon";
import ItemActionsMini from "./ItemActionsMini";

const cdn_url = process.env.NEXT_PUBLIC_cdn_url;

const ItemCard = (props: { catalogTypeSlug: any; item: CatalogItemNoImg }) => {
  const itemData = props.item;
  return (
    <div
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow focus:outline-none focus:ring-2 focus:ring-secondary"
      key={itemData.slug}
    >
      <a href={`/${itemData.slug}`}>
        <div className="flex flex-1 flex-col  p-3 xl:px-6 lg:px-5 sm:px-1 ">
          <div className="flex flex-col sm:flex-row mx-auto text-gray-500 grow max-w-full">
            {itemData.has_images ? (
              <>
                <picture className="flex justify-center items-center lg:h-20 lg:w-20 xl:h-24 xl:w-24 2xl:w-28 2xl:h-28 h-20 w-20 flex-shrink-0 p-1">
                  <source
                    type="image/webp"
                    width={250}
                    height={250}
                    srcSet={`${cdn_url}${itemData.slug}-front-thumbnail.webp`}
                  />
                  <source
                    type="image/jpeg"
                    width={250}
                    height={250}
                    srcSet={`${cdn_url}${itemData.slug}-front-thumbnail.jpg`}
                  />
                  <img
                    src={`${cdn_url}${itemData.slug}-front-thumbnail.jpg`}
                    width={250}
                    height={250}
                    className="max-h-full"
                    loading="lazy"
                    alt={`Anverso de ${itemData.title_es}`}
                  />
                </picture>
                <picture className="flex justify-center items-center lg:h-20 lg:w-20 xl:h-24 xl:w-24 2xl:w-28 2xl:h-28 h-20 w-20 flex-shrink-0 p-1 max-w-full">
                  <source
                    type="image/webp"
                    width={250}
                    height={250}
                    srcSet={`${cdn_url}${itemData.slug}-back-thumbnail.webp`}
                  />
                  <source
                    type="image/jpeg"
                    width={250}
                    height={250}
                    srcSet={`${cdn_url}${itemData.slug}-back-thumbnail.jpg`}
                  />
                  <img
                    src={`${cdn_url}${itemData.slug}-back-thumbnail.jpg`}
                    width={250}
                    height={250}
                    className="max-h-full"
                    loading="lazy"
                    alt={`Reverso de ${itemData.title_es}`}
                  />
                </picture>
              </>
            ) : props.catalogTypeSlug === "catalogo" ? (
              <>
                <CoinNoImageImg250 />
                <CoinNoImageImg250 />
              </>
            ) : (
              <>
                <BanknoteNoImageImg250 />
                <BanknoteNoImageImg250 />
              </>
            )}
          </div>
          <div className="mt-4 text-sm font-medium text-gray-900 h-[5rem]">
            <AutoResizeText>
              {itemData.title_es}
              <br />
              {itemData.min_year === itemData.max_year ? (
                <>{itemData.min_year}</>
              ) : (
                <>
                  {itemData.min_year} - {itemData.max_year}
                </>
              )}
            </AutoResizeText>
            <div className="flex text-sm text-gray-500 mt-1 justify-center inline">
              <span className="flex">{itemData.currency?.title_es}</span>
              <span className="flex items-center px-2 w-[2rem] h-[1.5rem]">
                <FlagIcon country={itemData.country?.name_es} />
              </span>
            </div>
          </div>
        </div>
      </a>

      <ItemActionsMini
        productSlug={itemData.slug}
        isInWishList={(itemData as any).isInWishlist}
        isInCatalogList={(itemData as any).isInCatalogList}
      />
    </div>
  );
};

export default ItemCard;
